import { State } from "../state";
import { DeviceState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { Device } from "@/interfaces/devices";

export const mutations = {
  setDevices(state: DeviceState, devices: Device[]): void {
    state.devices = devices;
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const { commit } = getStoreAccessors<DeviceState | any, State>("");

export const commitSetDevices = commit(mutations.setDevices);
