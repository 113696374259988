import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

const terracotta = "#c03b32";

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: terracotta,
        secondary: "#545560",
        accent: "#d0514a",
        slatelight: "#898989",
        salt: "#fbf3e8",
        watercorp: "#006BA6",
        skyblue: "#0066b6",
        skybluelight: "#1da8e9",
        error: colors.red.accent3,
        background: terracotta,
      },
      dark: {
        primary: colors.orange.darken4,
        background: terracotta,
      },
    },
  },
});
