import { State } from "../state";
import { MapState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
  mapZoom: (state: MapState): number => state.zoom,
  center: (state: MapState): { lat: number; lng: number } => state.center,
};

const { read } = getStoreAccessors<MapState, State>("");

export const readMapZoom = read(getters.mapZoom);
export const readMapCenter = read(getters.center);
