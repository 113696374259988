import { State } from "../state";
import { DeviceState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { Device } from "@/interfaces/devices";

export const getters = {
  devices: (state: DeviceState): Device[] => state.devices,
  deviceBySerialNumber:
    (state: DeviceState) =>
    (deviceSerial: string): Device => {
      return state.devices.filter(
        (device) => device.serial_number === deviceSerial
      )[0];
    },
  deviceById:
    (state: DeviceState) =>
    (deviceId: number): Device => {
      return state.devices.filter((device) => device.id === deviceId)[0];
    },
};

const { read } = getStoreAccessors<DeviceState, State>("");

export const readDevices = read(getters.devices);
export const readDeviceBySerialNumber = read(getters.deviceBySerialNumber);
export const readDeviceById = read(getters.deviceById);
