
import { Vue, Component, Watch } from "vue-property-decorator";
import { AppNotification } from "@/store/main/state";
import { commitRemoveNotification } from "@/store/main/mutations";
import { readFirstNotification } from "@/store/main/getters";
import { dispatchRemoveNotification } from "@/store/main/actions";

@Component
export default class NotificationsManager extends Vue {
  public show = false;
  public text = "";
  public showProgress = false;
  public currentNotification: AppNotification | false = false;

  public async hide(): Promise<void> {
    this.show = false;
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 500));
  }

  public async close(): Promise<void> {
    await this.hide();
    await this.removeCurrentNotification();
  }

  public async removeCurrentNotification(): Promise<void> {
    if (this.currentNotification) {
      commitRemoveNotification(this.$store, this.currentNotification);
    }
  }

  public get firstNotification(): AppNotification | false {
    return readFirstNotification(this.$store);
  }

  public async setNotification(
    notification: AppNotification | false
  ): Promise<void> {
    if (this.show) {
      await this.hide();
    }
    if (notification) {
      this.currentNotification = notification;
      this.showProgress = notification.showProgress || false;
      this.show = true;
    } else {
      this.currentNotification = false;
    }
  }

  @Watch("firstNotification")
  public async onNotificationChange(
    newNotification: AppNotification | false
  ): Promise<void> {
    if (newNotification !== this.currentNotification) {
      await this.setNotification(newNotification);
      if (newNotification) {
        await dispatchRemoveNotification(this.$store, {
          notification: newNotification,
          timeout: 6500,
        });
      }
    }
  }

  public get currentNotificationContent(): string {
    return (this.currentNotification && this.currentNotification.content) || "";
  }

  public get currentNotificationColor(): string {
    return (
      (this.currentNotification && this.currentNotification.color) || "info"
    );
  }

  public get currentNotificationTimeout(): number {
    if (this.currentNotification && this.currentNotification.timeout) {
      return this.currentNotification.timeout;
    }
    if (this.currentNotificationColor === "success") {
      return 1000;
    } else {
      return 6000;
    }
  }
}
