import { State } from "../state";
import { SiteConfigState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { SiteConfig } from "@/interfaces/site_config";
import { Settings } from "luxon";

export const mutations = {
  setSiteConfigs(state: SiteConfigState, siteConfigs: SiteConfig[]): void {
    state.siteConfigurations = siteConfigs;
  },
  setProjectTimeZone(state: SiteConfigState, timeZone: string): void {
    state.projectTimeZone = timeZone;
    // Set the default timezone for Luxon.
    // Must await to ensure datetime objects aren't created before the timezone is set.
    // If waiting for the site config is too slow, we can try to set the
    // timezone on each construction of a datetime object.
    Settings.defaultZone = timeZone;
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const { commit } = getStoreAccessors<SiteConfigState | any, State>("");

export const commitSetSiteConfigs = commit(mutations.setSiteConfigs);
export const commitSetProjectTimeZone = commit(mutations.setProjectTimeZone);
