import axios, { AxiosResponse } from "axios";
import { apiEventMonitorUrl, apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "@/interfaces";

import { CommentPost, Comment } from "@/interfaces/comments";
import {
  SiteConfig,
  SiteConfigCreate,
  SiteConfigUpdate,
} from "@/interfaces/site_config";
import { UploadedFileList } from "@/interfaces/uploaded_files";
import { EdgeJobCreate, PaginatedEdgeJobs } from "@/interfaces/edge_jobs";
import { BackendSyncChanges, BackendSyncItems } from "@/interfaces/sync";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function fileHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
}

export const backend = {
  async logInGetToken(
    username: string,
    password: string
  ): Promise<AxiosResponse> {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getPasswordRecoveryEnabled(): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/password-recovery-enabled`);
  },
  async refreshLoginToken(): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/login/refresh-access-token`);
  },
  async logout(token: string): Promise<void> {
    return axios.delete(`${apiUrl}/api/v1/logout`, authHeaders(token));
  },
  async getMe(token: string): Promise<AxiosResponse> {
    return axios.get<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      authHeaders(token)
    );
  },
  async updateMe(
    token: string,
    data: IUserProfileUpdate
  ): Promise<AxiosResponse> {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string, exclude_admin = true): Promise<AxiosResponse> {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, {
      params: { exclude_admin: exclude_admin },
      ...authHeaders(token),
    });
  },
  async updateUser(
    token: string,
    userId: number,
    data: IUserProfileUpdate
  ): Promise<AxiosResponse> {
    return axios.put(
      `${apiUrl}/api/v1/users/${userId}`,
      data,
      authHeaders(token)
    );
  },
  async deleteUser(token: string, userId: number): Promise<AxiosResponse> {
    return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async createUser(
    token: string,
    data: IUserProfileCreate
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async getUser(token: string, userId: number): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async passwordRecovery(email: string): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async allPositions(token: string): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/fibre_mapping/all/`, authHeaders(token));
  },
  async allPositionsForDeployment(
    token: string,
    deploymentId: number
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiUrl}/api/v1/fibre_mapping/all/${deploymentId}`,
      authHeaders(token)
    );
  },
  async getPosition(
    token: string,
    distanceIndex: number
  ): Promise<AxiosResponse> {
    return axios.get(
      `${apiUrl}/api/v1/fibre_mapping/${distanceIndex}`,
      authHeaders(token)
    );
  },
  async uploadFibreMapping(
    token: string,
    deploymentId: number,
    data: FormData
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiUrl}/api/v1/fibre_mapping/json/${deploymentId}/set_locations`,
      data,
      { ...fileHeaders(token) }
    );
  },

  async getAvailableTrebles(token: string): Promise<AxiosResponse> {
    return axios.get(
      `${apiUrl}/api/v1/messages/unique-serial-numbers/`,
      authHeaders(token)
    );
  },
  async getSiteConfigByCategory(
    token: string,
    category: string
  ): Promise<AxiosResponse<SiteConfig[]>> {
    return axios.get(
      `${apiUrl}/api/v1/siteconfig/by-category/${category}`,
      authHeaders(token)
    );
  },
  async getSiteConfig(token: string): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/siteconfig/`, authHeaders(token));
  },
  async updateSiteConfig(
    token: string,
    siteConfigId: number,
    data: SiteConfigUpdate
  ): Promise<AxiosResponse> {
    return axios.put(
      `${apiUrl}/api/v1/siteconfig/${siteConfigId}`,
      data,
      authHeaders(token)
    );
  },
  async createSiteConfig(
    token: string,
    data: SiteConfigCreate
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/siteconfig/`, data, authHeaders(token));
  },
  async deleteSiteConfig(token: string, id: number): Promise<AxiosResponse> {
    return axios.delete(
      `${apiUrl}/api/v1/siteconfig/${id}`,
      authHeaders(token)
    );
  },
  // Comments
  async getComments(
    token: string,
    limit = 10000
  ): Promise<AxiosResponse<Comment[]>> {
    return axios.get(
      `${apiUrl}/api/v1/comments/?limit=${limit}`,
      authHeaders(token)
    );
  },
  async postComment(
    token: string,
    comment: CommentPost
  ): Promise<AxiosResponse<Comment>> {
    return axios.post(
      `${apiUrl}/api/v1/comments/`,
      comment,
      authHeaders(token)
    );
  },
  async updateComment(
    token: string,
    commentId: number,
    comment: CommentPost
  ): Promise<AxiosResponse<Comment>> {
    return axios.put(
      `${apiUrl}/api/v1/comments/${commentId}`,
      comment,
      authHeaders(token)
    );
  },
  async deleteComment(
    token: string,
    commentId: number
  ): Promise<AxiosResponse<Comment>> {
    return axios.delete(
      `${apiUrl}/api/v1/comments/${commentId}`,
      authHeaders(token)
    );
  },
  async getActivityComments(
    token: string,
    activityId: number
  ): Promise<AxiosResponse<Comment[]>> {
    return axios.get(
      `${apiUrl}/api/v1/comments/activity/${activityId}`,
      authHeaders(token)
    );
  },
  async listAvailableAudioFiles(
    token: string,
    params: {
      skip?: number;
      limit?: number;
      location_id: number;
      start_time: string;
      end_time: string;
    }
  ): Promise<AxiosResponse<UploadedFileList>> {
    return axios.get(`${apiUrl}/api/v1/uploaded_files/audio`, {
      params,
      ...authHeaders(token),
    });
  },
  async listEdgeJobs(
    token: string,
    params: {
      sort_by?: string;
      sort_desc?: boolean;
      page?: number;
      per_page?: number;
      requested_by_emails?: string[];
      serial_numbers?: string[];
      statuses?: string[];
      operations?: string[];
    } = {}
  ): Promise<AxiosResponse<PaginatedEdgeJobs>> {
    return axios.post(
      `${apiUrl}/api/v1/edge_jobs/`,
      params,
      authHeaders(token)
    );
  },
  async listMyEdgeJobs(
    token: string,
    params: {
      sort_by?: string;
      sort_desc?: boolean;
      page?: number;
      per_page?: number;
    } = {}
  ): Promise<AxiosResponse<PaginatedEdgeJobs>> {
    return axios.get(`${apiUrl}/api/v1/edge_jobs/me`, {
      params,
      ...authHeaders(token),
    });
  },
  async submitEdgeJob(
    token: string,
    params: EdgeJobCreate
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiUrl}/api/v1/edge_jobs/submit`,
      params,
      authHeaders(token)
    );
  },
  async retryEdgeJob(token: string, jobId: number): Promise<AxiosResponse> {
    return axios.post(
      `${apiUrl}/api/v1/edge_jobs/${jobId}/retry`,
      null,
      authHeaders(token)
    );
  },
  async downloadEdgeJob(
    token: string,
    jobId: number,
    downloadProgress?: (progress: never) => void
  ): Promise<AxiosResponse<Blob>> {
    return axios.get(`${apiUrl}/api/v1/edge_jobs/${jobId}/download`, {
      ...authHeaders(token),
      responseType: "blob",
      onDownloadProgress: downloadProgress,
    });
  },
  async getAudioFFT(
    token: string,
    params: { file_id: number }
  ): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/uploaded_files/${params.file_id}/fft`, {
      params,
      ...authHeaders(token),
    });
  },
  async getOauthConfig(token: string): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/siteconfig/oauth2`, {
      ...authHeaders(token),
    });
  },
  async getProjectTimeZone(token: string): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/siteconfig/tz`, {
      ...authHeaders(token),
    });
  },
  async sendTestEmail(token: string, email: string): Promise<AxiosResponse> {
    return axios.post(
      `${apiUrl}/api/v1/utils/test-email/${email}`,
      { email: email },
      {
        ...authHeaders(token),
      }
    );
  },
  async sendTestTeamsMessage(
    token: string,
    webhook: string
  ): Promise<AxiosResponse> {
    return axios.post(
      `${apiUrl}/api/v1/utils/test-teams/`,
      { webhook_url: webhook },
      {
        ...authHeaders(token),
      }
    );
  },
  async generateSync(token: string): Promise<AxiosResponse<BackendSyncItems>> {
    return axios.get(`${apiUrl}/api/v1/sync/generate`, {
      ...authHeaders(token),
    });
  },
  async planSync(
    token: string,
    syncItems?: BackendSyncItems
  ): Promise<AxiosResponse<BackendSyncChanges>> {
    return axios.post(`${apiUrl}/api/v1/sync/plan-alternate`, syncItems, {
      ...authHeaders(token),
    });
  },
  async applySync(
    token: string,
    initiator: string,
    syncChanges?: BackendSyncChanges
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/sync/apply-alternate`, syncChanges, {
      ...authHeaders(token),
      params: { initiator },
    });
  },
  async checkSyncEnabled(token: string): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/sync/enabled`, {
      ...authHeaders(token),
    });
  },
  async getSyncLog(
    token: string,
    params: { page?: number; per_page?: number }
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/sync/log`, params, {
      ...authHeaders(token),
    });
  },
};
