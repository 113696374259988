import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import { commitSetMapZoom, commitSetMapCenter } from "./mutations";
import { MapState } from "./state";

type MainContext = ActionContext<MapState, State>;

export const actions = {
  async actionSetZoom(context: MainContext, payload: number): Promise<void> {
    commitSetMapZoom(context, payload);
  },
  async actionSetMapCenter(
    context: MainContext,
    payload: { lat: number; lng: number }
  ): Promise<void> {
    commitSetMapCenter(context, payload);
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const { dispatch } = getStoreAccessors<MapState | any, State>("");

export const dispatchSetMapZoom = dispatch(actions.actionSetZoom);
export const dispatchSetMapCenter = dispatch(actions.actionSetMapCenter);
