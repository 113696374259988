import { ActionContext } from "vuex";
import { State } from "../state";
import { DeviceState } from "./state";
import { commitSetDevices } from "./mutations";
import { getStoreAccessors } from "typesafe-vuex";
import { commitAddNotification } from "@/store/main/mutations";
import { eventMonitor } from "@/api/eventMonitor";
import { DeviceCreate } from "@/interfaces/devices";

// Pass in the state management context
type MainContext = ActionContext<DeviceState, State>;

export const actions = {
  async actionGetDevices(context: MainContext): Promise<void> {
    try {
      const response = await eventMonitor.getDevices(
        context.rootState.main.token
      );
      if (response) {
        commitSetDevices(context, response.data);
      }
    } catch (error) {
      console.log(error);
      commitAddNotification(context, {
        content: "Error getting devices",
        color: "error",
      });
    }
  },
  async actionCreateDevice(
    context: MainContext,
    deviceCreate: DeviceCreate
  ): Promise<void> {
    try {
      const response = await eventMonitor.createDevice(
        context.rootState.main.token,
        deviceCreate
      );
      if (response) {
        await dispatchActionGetDevices(context);
      }
    } catch (error) {
      console.log(`Add device failure`);
      console.log(error);
      commitAddNotification(context, {
        content: "Failed to add device",
        color: "error",
      });
    }
  },
  async actionDeleteDevice(
    context: MainContext,
    deviceSerial: string
  ): Promise<void> {
    try {
      const response = await eventMonitor.deleteDevice(
        context.rootState.main.token,
        deviceSerial
      );
      if (response) {
        await dispatchActionGetDevices(context);
      }
    } catch (error) {
      console.log(`Remove device failure`);
      console.log(error);
      commitAddNotification(context, {
        content: "Failed to remove device",
        color: "error",
      });
    }
  },
};

const { dispatch } = getStoreAccessors<DeviceState, State>("");

export const dispatchActionGetDevices = dispatch(actions.actionGetDevices);
export const dispatchActionCreateDevice = dispatch(actions.actionCreateDevice);
export const dispatchActionDeleteDevice = dispatch(actions.actionDeleteDevice);
