import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { DeviceState } from "./state";

const defaultState: DeviceState = {
  devices: [],
};

export const devicesModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
