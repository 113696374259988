const env = process.env.VUE_APP_ENV;

let envApiUrl = ""; //change to ip
let envEventMonitorApiUrl = "/event-monitor";
let envMaintenanceApiUrl = "/maintenance";
let envTrebleProxyApiUrl = "/treble_proxy";

if (env === "production") {
  envApiUrl = ``;
  envEventMonitorApiUrl = `/event-monitor`;
  envMaintenanceApiUrl = `/maintenance`;
  envTrebleProxyApiUrl = `/treble_proxy`;
} else if (env === "staging") {
  envApiUrl = ``;
  envEventMonitorApiUrl = `/event-monitor`;
  envMaintenanceApiUrl = `/maintenance`;
  envTrebleProxyApiUrl = `/treble_proxy`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
  envEventMonitorApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}/event-monitor`; //Remove for production
  envMaintenanceApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}/maintenance`; //Remove for production
  envTrebleProxyApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}/treble_proxy`; //Remove for production
}

export const apiUrl = envApiUrl;
export const apiEventMonitorUrl = envEventMonitorApiUrl;
export const apiMaintenanceUrl = envMaintenanceApiUrl;
export const apiTrebleProxyUrl = envTrebleProxyApiUrl;
export const appName = process.env.VUE_APP_NAME;
export const appBuildTag = process.env.VUE_APP_BUILD_TAG || "development";
