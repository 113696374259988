import { State } from "../state";
import { SiteConfigState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { SiteConfig } from "@/interfaces/site_config";

export const getters = {
  siteConfigurations: (state: SiteConfigState): SiteConfig[] =>
    state.siteConfigurations,
  getSiteConfigTimeZone: (state: SiteConfigState): string => {
    return state.projectTimeZone;
  },
  getMapCenter: (state: SiteConfigState): { lat: number; lon: number } => {
    const center_lat = state.siteConfigurations.find(
      (config) => config.name === "center_lat"
    )?.val;
    const center_lon = state.siteConfigurations.find(
      (config) => config.name === "center_lon"
    )?.val;
    if (center_lat && center_lon) {
      return { lat: Number(center_lat), lon: Number(center_lon) };
    }
    return { lat: 0, lon: 0 };
  },
  getMapZoom: (state: SiteConfigState): number => {
    const zoom = state.siteConfigurations.find(
      (config) => config.name === "zoom"
    )?.val;
    if (zoom) {
      return Number(zoom);
    }
    return 5;
  },
};

const { read } = getStoreAccessors<SiteConfigState, State>("");
export const readSiteConfigurations = read(getters.siteConfigurations);
export const readSiteTimeZone = read(getters.getSiteConfigTimeZone);
export const readSiteMapCenter = read(getters.getMapCenter);
export const readConfiguredMapZoom = read(getters.getMapZoom);
