import { AppNotification, MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { IUserProfile } from "@/interfaces";

export const getters = {
  hasAdminAccess: (state: MainState): boolean => {
    return (
      state.userProfile &&
      state.userProfile.is_superuser &&
      state.userProfile.is_active
    );
  },
  canAcknowledge: (state: MainState): boolean => {
    return (
      state.userProfile &&
      state.userProfile.can_acknowledge_activities &&
      state.userProfile.is_active
    );
  },
  loginError: (state: MainState): boolean => state.logInError,
  dashboardShowDrawer: (state: MainState): boolean => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState): boolean => state.dashboardMiniDrawer,
  userProfile: (state: MainState): IUserProfile => state.userProfile,
  token: (state: MainState): string => state.token,
  isLoggedIn: (state: MainState): boolean => state.isLoggedIn,
  firstNotification: (state: MainState): AppNotification =>
    state.notifications.length > 0 && state.notifications[0],
  readMsalConfig: (state: MainState): { clientId: string; authority: string } =>
    state.msalConfig,
  ssoEnabled: (state: MainState): boolean => {
    return (
      state.msalConfig.clientId !== "" && state.msalConfig.authority !== ""
    );
  },
  ssoLoggedIn: (state: MainState): boolean => state.ssoLoggedIn,
  tokenExpiry: (state: MainState): number => {
    if (state.token) {
      const token = state.token.split(".")[1];
      const decodedToken = JSON.parse(atob(token));
      return decodedToken.exp;
    }
    return 0;
  },
  mapBokToken: (state: MainState): string => state.mapBoxToken,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readCanAcknowledge = read(getters.canAcknowledge);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readMsalConfig = read(getters.readMsalConfig);
export const readSSOEnabled = read(getters.ssoEnabled);
export const readSSOLoggedIn = read(getters.ssoLoggedIn);
export const readTokenExpiry = read(getters.tokenExpiry);
export const readMapBoxToken = read(getters.mapBokToken);
