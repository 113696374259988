import { backend } from "@/api/backend";
import router from "@/router";
import {
  getLocalToken,
  removeLocalToken,
  removeMicrosoftCookieToken,
  saveLocalToken,
  setMicrosoftCookieToken,
} from "@/utils";
import { AxiosError } from "axios";

import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetOauthConfig,
  commitSetSSOLoggedIn,
  commitSetToken,
  commitSetUserProfile,
} from "./mutations";
import { AppNotification, MainState } from "./state";
import { AuthenticationResult } from "@azure/msal-browser";
import Vue from "vue";
import { IUserProfileUpdate } from "@/interfaces";
import { readMsalConfig } from "@/store/main/getters";
import * as msal from "@azure/msal-browser";
import { loginRequestScopes } from "@/oauthConfig";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogInSSO(
    context: MainContext,
    account: AuthenticationResult
  ): Promise<void> {
    const token = account.idToken;
    try {
      saveLocalToken(token);
      setMicrosoftCookieToken(token);
      commitSetToken(context, token);
      // If not logged show the notification
      if (!context.state.isLoggedIn) {
        commitAddNotification(context, {
          content: "Logged in",
          color: "success",
        });
      }
      commitSetSSOLoggedIn(context, true);
      commitSetLoggedIn(context, true);
      commitSetLogInError(context, false);
      await dispatchGetUserProfile(context);
      await dispatchRouteLoggedIn(context);
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionLogInSSOFailed(
    context: MainContext,
    error: string
  ): Promise<void> {
    commitAddNotification(context, {
      content: "Backend Server Error - Failed to login via SSO",
      color: "error",
    });
    console.error(error);
    await dispatchLogOut(context);
  },
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ): Promise<void> {
    try {
      const response = await backend.logInGetToken(
        payload.username,
        payload.password
      );
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, {
          content: "Logged in",
          color: "success",
        });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      // If not a 401 then it's a server error
      if (err.response && err.response.status !== 401) {
        commitAddNotification(context, {
          content: "Server unavailable",
          color: "error",
        });
      }
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext): Promise<void> {
    try {
      const response = await backend.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(
    context: MainContext,
    payload: IUserProfileUpdate
  ): Promise<void> {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          backend.updateMe(context.state.token, payload),
          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext): Promise<void> {
    // Checked on each route navigation to ensure that a user is logged in
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await backend.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
          Vue.$cookies.set("access_token_cookie", token);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRefreshLogin(context: MainContext): Promise<void> {
    await backend
      .refreshLoginToken()
      .then((response) => {
        const token = response.data.access_token;
        saveLocalToken(token);
        commitSetToken(context, token);
      })
      .catch((error) => {
        console.log(error);
        dispatchRemoveLogIn(context);
      });
  },
  async actionRefreshSSOToken(context: MainContext): Promise<void> {
    const clientApp = new msal.PublicClientApplication({
      auth: readMsalConfig(context),
    });
    const silentRequest = {
      account: clientApp.getAllAccounts()[0],
      scopes: loginRequestScopes,
      forceRefresh: true,
    };
    await clientApp
      .acquireTokenSilent(silentRequest)
      .then((tokenResponse) => dispatchLogInSSO(context, tokenResponse))
      .catch((error) => {
        console.log(error);
        dispatchLogInSSOFailed(context, error);
      });
  },
  async actionRemoveLogIn(context: MainContext): Promise<void> {
    removeLocalToken();
    removeMicrosoftCookieToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
    commitSetSSOLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext): Promise<void> {
    await backend.logout(context.state.token);
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext): Promise<void> {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: "Logged out", color: "success" });
  },
  async actionRouteLogOut(): Promise<void> {
    if (router.currentRoute.path !== "/login") {
      await router.push("/login");
    }
  },
  async actionCheckApiError(
    context: MainContext,
    payload: AxiosError
  ): Promise<void> {
    if (payload.response?.status === 401) {
      await dispatchLogOut(context);
    }
  },
  async actionRouteLoggedIn(): Promise<void> {
    if (router.currentRoute.path === "/login") {
      await router.push("/main/state");
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(
    context: MainContext,
    payload: { username: string }
  ): Promise<void> {
    const loadingNotification = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        backend.passwordRecovery(payload.username),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password recovery email sent",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Incorrect username",
      });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string }
  ): Promise<void> {
    const loadingNotification = {
      content: "Resetting password",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        backend.resetPassword(payload.password, payload.token),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Error resetting password",
      });
    }
  },
  async actionGetOauthConfig(context: MainContext): Promise<void> {
    const response = await backend.getOauthConfig(context.state.token);
    if (response.data.clientId && response.data.authority) {
      commitSetOauthConfig(context, response.data);
    }
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchRefreshLogin = dispatch(actions.actionRefreshLogin);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(
  actions.actionUpdateUserProfile
);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchLogInSSO = dispatch(actions.actionLogInSSO);
export const dispatchLogInSSOFailed = dispatch(actions.actionLogInSSOFailed);
export const dispatchGetOauthConfig = dispatch(actions.actionGetOauthConfig);
export const dispatchRefreshSSOToken = dispatch(actions.actionRefreshSSOToken);
