import LatLngLiteral = google.maps.LatLngLiteral;
import Vue from "vue";
import { DateTime } from "luxon";
import { InfrastructureStateLabel } from "@/interfaces/state_labels";

export const getLocalToken = (): string => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return Vue.$cookies.get("access_token_cookie");
};

export const saveLocalToken = (token: string): void =>
  localStorage.setItem("token", token);

export const removeLocalToken = (): void => {
  localStorage.removeItem("token");
  Vue.$cookies.remove("access_token_cookie");
};

export const getMicrosoftCookieToken = (): string => {
  return Vue.$cookies.get("msad_access_token_cookie");
};
export const setMicrosoftCookieToken = (token: string): void => {
  Vue.$cookies.set("msad_access_token_cookie", token);
};

export const removeMicrosoftCookieToken = (): void => {
  Vue.$cookies.remove("msad_access_token_cookie");
};

export enum DisplayMode {
  Audio = "audio",
  Events = "events",
  Alerts = "alerts",
  Heatmap = "heatmap",
}

export enum AlertSeverity {
  High = 2,
  Medium = 1,
  Low = 0,
  Unknown = 10,
}

export interface SeverityItem {
  icon: string;
  color: string;
  text: string;
  value: AlertSeverity;
}

export const displayTime = (inputTime: string): string => {
  return DateTime.fromISO(inputTime).toLocaleString(DateTime.DATETIME_SHORT);
};

export const convertPixelIndexToDistance = (pixelLocation: number): number => {
  return pixelLocation * 4.9 + 32;
};

export const getPathBounds = (
  fibrePath: LatLngLiteral[]
): { north: number; south: number; east: number; west: number } => {
  const north = Math.max(...fibrePath.map((o) => o.lat));
  const south = Math.min(...fibrePath.map((o) => o.lat));
  const east = Math.max(...fibrePath.map((o) => o.lng));
  const west = Math.min(...fibrePath.map((o) => o.lng));
  return { north, south, east, west };
};

export const dbScale = (value: number): number => {
  return 20 * Math.log10(value);
};

export const fftToPoints = (
  freqBins: number[],
  spectrum: number[]
): { x: number; y: number }[] => {
  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
  const toPoint = (freq, spec): { x: number; y: number } => ({
    x: freq + 1,
    y: dbScale(spec) - avgSpectrum,
  });
  const avgSpectrum = average(spectrum.map(dbScale));
  const points: Array<{ x: number; y: number }> = [];
  for (let i = 0; i < freqBins.length; i++) {
    points.push(toPoint(freqBins[i], spectrum[i]));
  }
  return points;
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function chunkSubstr(str: string, size: number): string[] {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
}

export type VForm = Vue & { validate: () => boolean; reset: () => void };

export const urlQueryString = (
  url: URL | string,
  params: Record<string, string>
): string => {
  return url + "?" + new URLSearchParams(params).toString();
};

export enum displayStates {
  outputState = 1,
  infrastructureState = 2,
}

export const authHeaders = (
  token: string
): { headers: { Authorization: string } } => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const sleep = (timeout: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const getIconSVG = (item: InfrastructureStateLabel): string => {
  switch (item.label) {
    case "Normal":
      return "check";
    case "No Data":
      return require("@/assets/state_icons/no-data.svg");
    case "Broken Fiber":
      return require("@/assets/state_icons/broken-fibre.svg");
    case "Broken Fibre":
      return require("@/assets/state_icons/broken-fibre.svg");
    case "Manual Digging":
      return require("@/assets/state_icons/manual-digging.svg");
    case "Mechanical Digging":
      return require("@/assets/state_icons/mechanical-digging.svg");
    case "Suspected Small Leak":
      return require("@/assets/state_icons/leak.svg");
    case "Suspected Large Leak":
      return require("@/assets/state_icons/leak.svg");
    case "Leak":
      return require("@/assets/state_icons/leak.svg");
    case "Vehicle":
      return require("@/assets/state_icons/vehicle.svg");
    case "Machinery":
      return require("@/assets/state_icons/machinery.svg");
    default:
      return "";
  }
};

export const getIcon = (item: InfrastructureStateLabel): string => {
  switch (item.label) {
    case "Normal":
      return "";
    case "No Data":
      return "sensors_off";
    case "Broken Fiber":
      return "heart_broken";
    case "Broken Fibre":
      return "heart_broken";
    case "Manual Digging":
      return "mdi-shovel";
    case "Mechanical Digging":
      return "mdi-excavator";
    case "Suspected Small Leak":
      return "mdi-pipe-leak";
    case "Suspected Large Leak":
      return "mdi-pipe-leak";
    case "Leak":
      return "mdi-pipe-leak";
    case "Vehicle":
      return "airport_shuttle";
    case "Machinery":
      return "mdi-cogs";
    default:
      return "";
  }
};
