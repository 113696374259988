// Import Component hooks before component definitions
import "./component-hooks";
import Vue from "vue";
import "./registerServiceWorker";
import App from "./App.vue";
import router from "./router";
import { store } from "@/store";
import vuetify from "./plugins/vuetify";
import "./plugins/vue-cookies";
import "./validation/rules";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.use(VueCookies);
Vue.$cookies.config("7d");

// Disable for now as unused
// const socket = io(apiUrl, {
//   path: "/api/v1/ws/socket.io/"
// });
// Vue.use(VueSocketIOExt, socket);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
