
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import {
  readIsLoggedIn,
  readSSOLoggedIn,
  readTokenExpiry,
} from "@/store/main/getters";
import {
  dispatchCheckLoggedIn,
  dispatchLogOut,
  dispatchRefreshSSOToken,
} from "@/store/main/actions";
import NotificationsManager from "@/components/NotificationsManager.vue";
import axios from "axios";

@Component({
  components: { NotificationsManager },
})
export default class App extends Vue {
  tokenRefreshTimout: ReturnType<typeof setTimeout> | null = null;
  private loggedIn(): boolean | null {
    return readIsLoggedIn(this.$store);
  }

  private logout(): void {
    dispatchLogOut(this.$store);
  }

  public created(): void {
    axios.defaults.withCredentials = true;
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
          console.log("Got 401, logging out");
          // Force the user to log in again immediately
          setTimeout(() => {
            // Reload the page to force a re-login
            window.location.reload();
          }, 0);
        }
        return Promise.reject(error);
      }
    );
    dispatchCheckLoggedIn(this.$store);
  }

  get refreshTime(): number {
    return readTokenExpiry(this.$store) - 5 * 60;
  }

  @Watch("refreshTime")
  private scheduleRefresh() {
    if (!readIsLoggedIn(this.$store) || this.refreshTime <= 0) {
      // May not have a token yet, or it's already expired
      return this.clearTimeout();
    }
    if (!readSSOLoggedIn(this.$store)) {
      // TODO: Refresh the tokens for non-SSO logins
      console.log("Not logged in with SSO not scheduling refresh");
      return this.clearTimeout();
    }

    console.log(
      `Token will expire at ${readTokenExpiry(
        this.$store
      )} scheduling refresh for ${this.refreshTime}`
    );
    this.tokenRefreshTimout = setTimeout(async () => {
      console.log("Refreshing SSO token");
      await dispatchRefreshSSOToken(this.$store);
    }, this.refreshTime);
  }

  public beforeDestroy(): void {
    this.clearTimeout();
  }

  private clearTimeout(): void {
    if (this.tokenRefreshTimout) {
      clearTimeout(this.tokenRefreshTimout);
    }
  }
}
